<template>
  <div class="top">
    <FirstCard :contrary="contrary"></FirstCard>
    <SecondCard :contrary="contrary"></SecondCard>
    <ThreeCard :contrary="contrary"></ThreeCard>
    <FourCard :contrary="contrary"></FourCard>
  </div>
</template>

<script setup>
import bus from "../../../utils/index";
import FirstCard from "./components/first-card/index";
import SecondCard from "./components/second-card/index";
import ThreeCard from "./components/three-card/index";
import FourCard from "./components/four-card/index";
const contrary = {
  "Transaction-guarantee": "交易保障",
  "Life-service": "生活服务",
  "Medical-health": "医疗健康",
  "Pet-consumption": "宠物消费",
  "Intelligent-hardware": "智能硬件",
  "Exercise-fitness": "运动健身",
  "Flexible-hardworking": "灵活用工",
  "Travel-Tourism": "出行旅游",
  "Enterprise-services": "企业服务",
  "Catering-consumption": "餐饮消费",
  "Financial-security": "金融保障",
  "Smart-community": "智慧社区",
  "Anti-counterfeiting-traceability": "防伪溯源",
};
bus.off("transaction");
bus.on("transaction", () => {
  window, scrollTo(0, 0);
});
</script>

<style scoped>
.top {
  margin-top: 80px;
}
</style>
