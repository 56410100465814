<template>
  <div class="nav">
    <div class="conxt">
      <TitleComponent :text="text"></TitleComponent>
      <div class="card">
        <div class="left">
          <div v-for="(item, index) in data[type].card" :key="index">
            <!-- <img :src="item.icon" alt=""> -->
            <img v-lazy="item.icon" alt="" :key="item.icon" />
            <p class="p1">{{ item.title }}</p>
            <p class="p2">{{ item.text }}</p>
          </div>
        </div>
        <div class="float"></div>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import TitleComponent from "../../../../components/titleComponent.vue";
import baoxianchanp from "../../../../assets/img/transaction/baoxianchanp@2x.png";
import changjing from "../../../../assets/img/transaction/changjing@2x.png";
import yunying from "../../../../assets/img/transaction/yunying@2x.png";
const router = useRouter();
const props = defineProps(["contrary"]);
let type = ref("交易保障");
const text = '<div style="color:#fff">全方位解决您的企业运营困惑</div>';
const data = {
  交易保障: {
    card: [
      {
        icon: baoxianchanp,
        title: "丰富的保险产品 灵活组合",
        text: "从商家保障、交易体验、商品品质到售后服务，秦保拥有数十款标准保障方案，用以助力合作方提升交易链路的各方体验",
      },
      {
        icon: changjing,
        title: "依托业务场景 精准定制",
        text: "应用前沿科技，构建实时、个性化的保险产品，以合作方业务场景为基础，综合考虑供需要求，提供创新产品",
      },
      {
        icon: yunying,
        title: "持续输出运营经验",
        text: "深入合作方产品使用场景共创风险保障方案，帮助合作方提升用户服务体验",
      },
    ],
  },
  生活服务: {
    card: [
      {
        icon: baoxianchanp,
        title: "标准保险,快速对接",
        text: "针对劳动者的风险以及家庭场景的风险，众安可以提供标准的人身意外保险与家财保险，可快速对接",
      },
      {
        icon: changjing,
        title: "按人、时、单灵活定制",
        text: "深入业务场景共创风险保障方案，对应业务场景的高频化，将保障进行碎片化创新，为合作方的用户或劳动者提供全新的服务体验",
      },
      {
        icon: yunying,
        title: "全面保障关键风险",
        text: "识别企业产业链上下游关键风险，提供各类企业自身必需的财产保障、场所责任、职业责任、网络安全等相关保险方案",
      },
    ],
  },
  医疗健康: {
    card: [
      {
        icon: baoxianchanp,
        title: "标准保险 快速对接",
        text: "可快速接入全年龄层、慢性病人群等特色健康产品；可根据企业场景创新定制实时、个性化保障方案",
      },
      {
        icon: changjing,
        title: "专业领域 全面保障",
        text: "着医疗健康领域专业人士的风险意识的提高。秦保可提供针对专业人士的全面的风险解决方案",
      },
      {
        icon: yunying,
        title: "链接生态 延展服务",
        text: "根据合作方的业务情况，众安可帮助链接如互联网医院，员工福利平台等多个应用，助力合作方延展服务链条",
      },
    ],
  },
  宠物消费: {
    card: [
      {
        icon: baoxianchanp,
        title: "宠物医疗保险",
        text: "为合作方提供宠物医疗保险产品",
      },
      {
        icon: changjing,
        title: "灵活定制保障",
        text: "按人、次、规模等多维度提供责任险等定制保险服务",
      },
      {
        icon: yunying,
        title: "宠物服务合作",
        text: "快速链接宠物主及宠物医院、宠物美容商店等服务、产品供应商",
      },
    ],
  },
  智能硬件: {
    card: [
      {
        icon: baoxianchanp,
        title: "全面保障关键风险",
        text: "为合作方产业链上下游提供标准的财险保障、质量保证、网络安全、产品责任相关保险方案",
      },
      {
        icon: changjing,
        title: "依托业务场景 精准定制",
        text: "深入合作方产品使用场景共创风险保障方案，帮助合作方提升用户服务体验",
      },
      {
        icon: yunying,
        title: "链接生态 延展服务",
        text: "根据合作方的业务市场及拓展情况，为其延展如维保服务、海外出口产品责任风险保障等",
      },
    ],
  },
  运动健身: {
    card: [
      {
        icon: baoxianchanp,
        title: "产品丰富 快速对接",
        text: "快速获取不同运动、比赛、健身场景下的健康及意外伤害保障",
      },
      {
        icon: changjing,
        title: "深入场景 创新定制",
        text: "与平台方案的业务场景、用户活跃、运动情况、健康测评等信息深度结合设计定制化产品",
      },
      {
        icon: yunying,
        title: "生态互联 开拓市场",
        text: "秦保与服务合作方一同，为企业客户提供组织拓展+文化建设+运动健身+保险服务一体化解决方案",
      },
    ],
  },
  灵活用工: {
    card: [
      {
        icon: baoxianchanp,
        title: "标准保险产品",
        text: "为用工单位、劳动者、平台方提供丰富的标准健康、意外、雇主责任类保险产品",
      },
      {
        icon: changjing,
        title: "按人、时、单灵活定制",
        text: "根据劳动时间、用工人数、薪酬结算方式等元素，定制专属风险保障方案，配置方式更加自由",
      },
      {
        icon: yunying,
        title: "智能办公、智能职场科技应用",
        text: "通过科技工具应用，帮助平台方长期留存高质量劳动者，助力用工企业高效管理",
      },
    ],
  },
  出行旅游: {
    card: [
      {
        icon: baoxianchanp,
        title: "标准保险,快速对接",
        text: "基于秦保对旅行类险种的不断丰富与迭代，针对出行意外、航班延误、酒店取消等高频风险的标准保险产品，可快速对接使用",
      },
      {
        icon: changjing,
        title: "即时高效，精准定制",
        text: "应用前沿科技，构建实时、个性化保险服务，以需求为核心做风险拆解，消费者可按照行程、订单、时间进行投保",
      },
      {
        icon: yunying,
        title: "全面保障，关键风险",
        text: "从基础的财产综合与公众责任险，场所责任险，到支持小微企业的经营发展的保证金保险，都可以根据合作方需求进行快速搭配组合",
      },
    ],
  },
  企业服务: {
    card: [
      {
        icon: baoxianchanp,
        title: "综合保障方案",
        text: "提供网络安全责任、企业财产、公众责任等综合保障",
      },
      {
        icon: changjing,
        title: "灵活定制保障",
        text: "按行业、时间等提供团险、雇主责任险等定制保障服务",
      },
      {
        icon: yunying,
        title: "企业服务输出",
        text: "提供商保直付团险产品，贯通“医药健保”生态,同时链接伙伴最福利，助力企业员工福利管理",
      },
    ],
  },
  餐饮消费: {
    card: [
      {
        icon: baoxianchanp,
        title: "产品丰富 快速对接",
        text: "针对从业者的风险以及家庭场景的风险，众安可以提供标准的人身意外保险、健康保险，可快速实现对接",
      },
      {
        icon: changjing,
        title: "即时高效，精准定制",
        text: "深入场景共创风险保障方案，以需求为核心做风险拆解，根据企业规模、场所特性、业务模式，为企业提供个性化、差异化的保障方案",
      },
      {
        icon: yunying,
        title: "全面保障 关键风险",
        text: "识别企业产业链上下游关键风险，提供各类企业自身必需的财产保障、场所责任、职业责任、网络安全等相关保险方案",
      },
    ],
  },
  金融保障: {
    card: [
      {
        icon: baoxianchanp,
        title: "产品丰富 快速对接",
        text: "秦保拥有涵盖健康、疾病、意外，家庭财产等各类丰富的保险产品，可快速对接，帮助合作方合规高效一键解锁保险市场",
      },
      {
        icon: changjing,
        title: "即时高效，精准定制",
        text: "深入场景共创风险保障方案，从保障范围、保障责任、保障期限进行灵活定制，形成专属保险产品，突出合作方品牌力，提升用户体验",
      },
      {
        icon: yunying,
        title: "科技助力 数字升级",
        text: "从核保风控、信息安全、营销话术培训，到营销反欺诈、可视化回溯，秦保提供成套科技应用，结合合作方数字战略，高效实现保险业务落地",
      },
    ],
  },
  智慧社区: {
    card: [
      {
        icon: baoxianchanp,
        title: "标准产品，快速对接",
        text: "提供标准的家庭财产保障方案，包括房屋、室内财产、水管爆裂、第三者责任等",
      },
      {
        icon: changjing,
        title: "即时高效，精准定制",
        text: "构建实时、个性化保险服务，以需求为核心做风险拆解，可按人、订单、次数等定制保障方案",
      },
      {
        icon: yunying,
        title: "快速链接优质服务",
        text: "快速链接业主与服务商，提供管道疏通、维修等优质服务，同时可以为上门人员提供意外伤害和第三者责任保障",
      },
    ],
  },
  防伪溯源: {
    card: [
      {
        icon: baoxianchanp,
        title: "标准产品，快速对接",
        text: "针对防伪溯源场景，为商品提供真实的产地、品牌、品质等信誉保障，可快速实现对接",
      },
      {
        icon: changjing,
        title: "依托业务场景，精准定制",
        text: "深入合作方产品使用场景共创风险保障方案，综合考虑供需要求，提供创新产品",
      },
      {
        icon: yunying,
        title: "链接防伪溯源生态，延展服务",
        text: "根据合作方的业务情况，众安可帮助链接如防伪溯源平台、鉴定机构等多个生态，助力合作方延展服务链条",
      },
    ],
  },
};
//根据query值的变化，展示不同解决方案的数据
onMounted(() => {
  if (router.currentRoute.value.query.id) {
    type.value = props.contrary[router.currentRoute.value.query.id];
  }
});
//根据query值的变化，展示不同解决方案的数据
watch(
  () => router.currentRoute.value.query.id,
  (newValue) => {
    if (newValue) {
      type.value = props.contrary[router.currentRoute.value.query.id];
    }
  }
);
</script>

<style scoped>
.nav {
  position: relative;
  height: 563px;
  background: url("../../../../assets/img/transaction/yunying_bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.conxt {
  position: absolute;
  width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 94px;
}
.card {
  /* width: 1616px;
    width: 78.9vw;
    height: 330px; */
  width: 100vw;
  margin-top: 80px;
  background: linear-gradient(
    98deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.89) 100%
  );
  border-radius: 20px 0px 0px 0px;
  /* display: flex;
    justify-content: space-between; */
}
.card .left {
  width: 1200px;
  height: 330px;
  display: flex;
  justify-content: space-between;
}
.card div {
  /* display: inline-block; */
  width: 406px;
  height: 243px;
  margin-top: 45px;
}
.card span:nth-of-type(1) {
  position: absolute;
  top: 180px;
  left: 406px;
  width: 1px;
  height: 243px;
  background: linear-gradient(
    rgba(217, 206, 193, 0),
    #daccc4,
    rgba(217, 206, 193, 0)
  );
}
.card span:nth-of-type(2) {
  position: absolute;
  top: 180px;
  left: 812px;
  width: 1px;
  height: 243px;
  background: linear-gradient(
    rgba(217, 206, 193, 0),
    #daccc4,
    rgba(217, 206, 193, 0)
  );
}
.card .float {
  position: absolute;
  right: -35.5%;
  /* right: -13.3%; */
  top: 94px;
  /* width: 420px; */
  /* width: 21vw; */
  height: 330px;
  background: linear-gradient(
    98deg,
    #001429 0%,
    rgba(255, 255, 255, 0.89) 100%
  );
  border-radius: 20px 0px 0px 0px;
  opacity: 0.08;
  /* border-right:1px solid red ; */
}
.card img {
  width: 60px;
  height: 60px;
  margin: 0 0 20px 68px;
}
.card .p1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #2a2a2a;
  margin: 0 0 15px 68px;
}
.card .p2 {
  width: 269px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  margin: 0 0 0 68px;
  line-height: 23px;
}
</style>
