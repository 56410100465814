<template>
  <div class="can">
    <!-- <img :src="data[type].icon" alt="" /> -->
    <img v-lazy="data[type].icon" alt="" />
    <div class="conxt">
      <p class="p1">{{ data[type].title }}</p>
      <p class="p2">{{ data[type].text }}</p>
      <button @click="jump">立即使用</button>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import jiaoyibaozhang from "../../../../assets/img/transaction/jiaoyibaozhang/jiaoyibaozhang_bg.png";
import shenghuofuwu from "../../../../assets/img/transaction/shenghuofuwu/fangweisuyuan_banner.png";
import yiliaojiankang from "../../../../assets/img/transaction/yiliaojiankang/banner.png";
import chongwuxiaofei from "../../../../assets/img/transaction/chongwuxiaofei/banner.png";
import zhinengyinjian from "../../../../assets/img/transaction/zhinengyinjian/zhinengyinjian_banner.png";
import yundongjiansheng from "../../../../assets/img/transaction/yundongjiansheng/banner.png";
import linghuoyonggong from "../../../../assets/img/transaction/linghuoyonggong/banner.png";
import chuxinglvyou from "../../../../assets/img/transaction/chuxinglvyou/banner.png";
import qiyefuwu from "../../../../assets/img/transaction/qiyefuwu/yiyefuwu_banner.png";
import canyinxiaofei from "../../../../assets/img/transaction/canyinxiaofei/banner.png";
import jinrongbaozhang from "../../../../assets/img/transaction/jinrongbaozhang/banner.png";
import zhihuishequ from "../../../../assets/img/transaction/zhihuishequ/fangweisuyuan_banner.png";
import fangweisuyuan from "../../../../assets/img/transaction/fangweisuyuan/jiejuefangan.png";
const router = useRouter();
let type = ref("交易保障");
const props = defineProps(["contrary"]);
const data = {
  交易保障: {
    icon: jiaoyibaozhang,
    title: "交易保障场景解决方案",
    text: "秦保依托在互联网生态丰富的业务经验，持续拓展服务边界，推动数字消费新业态、新模式的蓬勃发展，为企业提供数字消费场景下人、货、场的风险保障，以及为数字体验、数字资产、原创内容等新标的提供创新保障解决方案。",
  },
  生活服务: {
    icon: shenghuofuwu,
    title: "生活服务行业解决方案",
    text: "基于秦保先进的科技能力及丰富的互联网保险落地经验，提供高频、碎片、场景化的风险保障，助力科技+生活服务类的创新企业，全面提升风险管理能力，增强用户服务体验",
  },
  医疗健康: {
    icon: yiliaojiankang,
    title: "医疗健康行业解决方案",
    text: "众安拥有丰富的健康险品类与产品创新能力，具备为用户提供从疾病预防、跟踪干预、健康改善、健康保障到医疗服务的全闭环服务经验，掌握应对海量、碎片、高并发业务的核心技术。以产品+服务+技术帮助医疗健康领域的创新企业，布局全新增长赛道，提升用户服务体验。",
  },
  宠物消费: {
    icon: chongwuxiaofei,
    title: "宠物消费行业解决方案",
    text: "宠物保险很好的契合了宠物消费行业，通过保险快速链接用户和宠物服务机构，帮助企业带来新的业务增长点，并且企业还能通过公众责任等保险分散经营风险。",
  },
  智能硬件: {
    icon: zhinengyinjian,
    title: "智能硬件行业解决方案",
    text: "伴随政府、企业、消费者对各类智能硬件的需求呈现爆发式增长，智能化应用所带来的风险不容忽视，秦保依托技术优势与保险创新经验，为合作方提供合规、避险、保障、增效的保险+科技行业解决方案",
  },
  运动健身: {
    icon: yundongjiansheng,
    title: "运动健身行业解决方案",
    text: "秦保拥有面向消费者以及运动健身企业丰富的标准保险产品，基于大健康领域的产品、运营与科技沉淀，能够在深度融入场景的条件下，为企业及其消费者提供识别风险，控制风险，保障风险的一站式解决方案。+科技行业解决方案",
  },
  灵活用工: {
    icon: linghuoyonggong,
    title: "灵活用工行业解决方案",
    text: "伴随平台经济的高速发展，灵活就业劳动者的权益保障问题备受关注，秦保拥有丰富灵活的保险产品与数字化员工服务应用，能够为全新业态下的用工方、平台方、劳动者提供一揽子风险保障解决方案",
  },
  出行旅游: {
    icon: chuxinglvyou,
    title: "出行旅游行业解决方案",
    text: "针对“游”“行”“住”场景下，企业面临的场所责任、经营管理、业务流程的风险，秦保可提供从消费者人身安全到企业财产保护的全面风险保障，并以科技为引擎，驱动业务智能、高效地实现落地。",
  },
  企业服务: {
    icon: qiyefuwu,
    title: "企业服务解决方案",
    text: "帮助企业建立完善的福利体系，为企业主转嫁企业用工风险，经营风险。解除员工的后顾之虑，又可以减少企业的负担，推动企业健康发展",
  },
  餐饮消费: {
    icon: canyinxiaofei,
    title: "餐饮消费行业解决方案",
    text: "秦保专注于为餐饮行业的供应链上下游、员工及场所的关键风险提供方案，保障传统餐饮向智慧餐饮升级过程中，线上与线下融合，逐步零售化而衍生出的场景化、高频风险。",
  },
  金融保障: {
    icon: jinrongbaozhang,
    title: "金融保障行业解决方案",
    text: "随着大众金融保险意识的增强，保险中介机构得到迅猛发展，专业保险机构急需数字化转型伙伴，银保业务也需进化升级。在此背景下，秦保为合作伙伴提供全面的保险产品与专属定制服务，以及助力保险业务合规落地的一站式科技解决方案。",
  },
  智慧社区: {
    icon: zhihuishequ,
    title: "智慧社区行业解决方案",
    text: "智慧社区是社区管理的一种新理念，充分利用物联网、移动互联网等应用,为社区居民提供一个安全、舒适、便利的现代化、智慧化生活环境，秦保可提供从家庭财产保障到智慧社区责任风险保障，并以科技为引擎，驱动业务智能、高效地实现落地。",
  },
  防伪溯源: {
    icon: fangweisuyuan,
    title: "防伪溯源场景解决方案",
    text: "为企业提供在产品溯源、供应链管控过程中的风险保障，协助企业快速建立满足国家与市场监管的追溯体系，为商品提供真实的产地、品牌、品质等信誉保障，促进产品流通业态积极健康发展",
  },
};
//根据query值的变化，展示不同解决方案的数据
onMounted(() => {
  if (router.currentRoute.value.query.id) {
    type.value = props.contrary[router.currentRoute.value.query.id];
  }
});
//根据query值的变化，展示不同解决方案的数据
watch(
  () => router.currentRoute.value.query.id,
  (newValue) => {
    if (newValue) {
      type.value = props.contrary[router.currentRoute.value.query.id];
    }
  }
);
const jump = () => {
  window.location.href = "https://www.wjx.cn/vm/rruZ6pb.aspx";
};
</script>

<style scoped>
.can {
  position: relative;
}
.can img {
  height: 500px;
  width: 100%;
  vertical-align: bottom;
}
.conxt {
  position: absolute;
  width: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.conxt .p1 {
  font-size: 68px;
  font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
}
.conxt .p2 {
  width: 689px;
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
  margin: 25px 0 40px 0;
  line-height: 28px;
}
.conxt button {
  width: 166px;
  height: 40px;
  background: #40335e;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.64);
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.conxt button:hover {
  background: #6a5e88;
  cursor: pointer;
}
</style>
