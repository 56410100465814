<template>
  <div class="nav">
    <div class="conxt">
      <TitleComponent :text="text"></TitleComponent>
      <div class="card">
        <div v-for="(item, index) in data[type].card" :key="index" class="find">
          <!-- <img :src="item.icon" alt=""> -->
          <img v-lazy="item.icon" alt="" />
          <p class="p1">{{ item.title }}</p>
          <p class="p2">
            <span></span>
            <span></span>
          </p>
          <p class="p3">{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import TitleComponent from "../../../../components/titleComponent.vue";
//交易保障
import jioayi_jiazhi_1 from "../../../../assets/img/transaction/jiaoyibaozhang/jiazhi_1.png";
import jioayi_jiazhi_2 from "../../../../assets/img/transaction/jiaoyibaozhang/jiazhi_2.png";
import jioayi_jiazhi_3 from "../../../../assets/img/transaction/jiaoyibaozhang/jiazhi_3.png";
//生活服务
import shenghuo_jiazhi_1 from "../../../../assets/img/transaction/shenghuofuwu/jiazhi_1.png";
import shenghuo_jiazhi_2 from "../../../../assets/img/transaction/shenghuofuwu/jiazhi_2.png";
import shenghuo_jiazhi_3 from "../../../../assets/img/transaction/shenghuofuwu/jiazhi_3.png";
//医疗健康
import yiliao_jiazhi_1 from "../../../../assets/img/transaction/yiliaojiankang/jiazhi_1.png";
import yiliao_jiazhi_2 from "../../../../assets/img/transaction/yiliaojiankang/jiazhi_2.png";
import yiliao_jiazhi_3 from "../../../../assets/img/transaction/yiliaojiankang/jiazhi_3.png";
//宠物消费
import chongwu_jiazhi_1 from "../../../../assets/img/transaction/chongwuxiaofei/jiazhi_1.png";
import chongwu_jiazhi_2 from "../../../../assets/img/transaction/chongwuxiaofei/jiazhi_2.png";
import chongwu_jiazhi_3 from "../../../../assets/img/transaction/chongwuxiaofei/jiazhi_3.png";
//智能硬件
import zhineng_jiazhi_1 from "../../../../assets/img/transaction/zhinengyinjian/jiazhi_1.png";
import zhineng_jiazhi_2 from "../../../../assets/img/transaction/zhinengyinjian/jiazhi_2.png";
import zhineng_jiazhi_3 from "../../../../assets/img/transaction/zhinengyinjian/jiazhi_3.png";
//运动健身
import yundong_jiazhi_1 from "../../../../assets/img/transaction/yundongjiansheng/jiazhi_1.png";
import yundong_jiazhi_2 from "../../../../assets/img/transaction/yundongjiansheng/jiazhi_2.png";
import yundong_jiazhi_3 from "../../../../assets/img/transaction/yundongjiansheng/jiazhi_3.png";
//灵活用工
import linghuo_jiazhi_1 from "../../../../assets/img/transaction/linghuoyonggong/jiazhi_1.png";
import linghuo_jiazhi_2 from "../../../../assets/img/transaction/linghuoyonggong/jiazhi_2.png";
import linghuo_jiazhi_3 from "../../../../assets/img/transaction/linghuoyonggong/jiazhi_3.png";
//出行旅游
import chuxing_jiazhi_1 from "../../../../assets/img/transaction/chuxinglvyou/jiazhi_1.png";
import chuxing_jiazhi_2 from "../../../../assets/img/transaction/chuxinglvyou/jiazhi_2.png";
import chuxing_jiazhi_3 from "../../../../assets/img/transaction/chuxinglvyou/jiazhi_3.png";
//企业服务
import qiye_jiazhi_1 from "../../../../assets/img/transaction/qiyefuwu/jiazhi_1.png";
import qiye_jiazhi_2 from "../../../../assets/img/transaction/qiyefuwu/jiazhi_2.png";
import qiye_jiazhi_3 from "../../../../assets/img/transaction/qiyefuwu/jiazhi_3.png";
//餐饮消费
import canyin_jiazhi_1 from "../../../../assets/img/transaction/canyinxiaofei/jiazhi_1.png";
import canyin_jiazhi_2 from "../../../../assets/img/transaction/canyinxiaofei/jiazhi_2.png";
import canyin_jiazhi_3 from "../../../../assets/img/transaction/canyinxiaofei/jiazhi_3.png";
//金融保障
import jinrong_jiazhi_1 from "../../../../assets/img/transaction/jinrongbaozhang/jiazhi_1.png";
import jinrong_jiazhi_2 from "../../../../assets/img/transaction/jinrongbaozhang/jiazhi_2.png";
import jinrong_jiazhi_3 from "../../../../assets/img/transaction/jinrongbaozhang/jiazhi_3.png";
//智慧社区
import zhihui_jiazhi_1 from "../../../../assets/img/transaction/zhihuishequ/jiazhi_1.png";
import zhihui_jiazhi_2 from "../../../../assets/img/transaction/zhihuishequ/jiazhi_2.png";
import zhihui_jiazhi_3 from "../../../../assets/img/transaction/zhihuishequ/jiazhi_3.png";
//防伪溯源
import fangwei_jiazhi_1 from "../../../../assets/img/transaction/fangweisuyuan/jiazhi_1.png";
import fangwei_jiazhi_2 from "../../../../assets/img/transaction/fangweisuyuan/jiazhi_2.png";
import fangwei_jiazhi_3 from "../../../../assets/img/transaction/fangweisuyuan/jiazhi_3.png";

const text = "我们从中获得价值";
const props = defineProps(["contrary"]);
const data = {
  交易保障: {
    card: [
      {
        icon: jioayi_jiazhi_1,
        title: "全面保障数字消费链路中所涉及的各种风险",
        text: "为数字消费业态下供需环节中所涉及的商品、人员、流程、信息等，提供各类商家保证金、产品责任、账户安全、信息安全等综合风险保障",
      },
      {
        icon: jioayi_jiazhi_2,
        title: "协助平台型企业商家治理与风险管控",
        text: "协助平台完善商家治理，以保险提供的赔偿金、服务补偿等方式处理交易纠纷，有效提升售后体验，助力平台合规稳健经营",
      },
      {
        icon: jioayi_jiazhi_3,
        title: "支撑营销增长，根据策略提供灵活保障",
        text: "可根据合作方的营销政策，为消费者提供按订单、按人员、按责任、按产品/服务组合提供碎片化保障，提升体验、转嫁风险",
      },
    ],
  },
  生活服务: {
    card: [
      {
        icon: shenghuo_jiazhi_1,
        title: "保障人员安全与健康",
        text: "保障因从业人员多，服务范围广存在从业人员工作过程中所产生的各类意外风险，以及自身的健康疾病风险",
      },
      {
        icon: shenghuo_jiazhi_2,
        title: "保障场所财产与责任",
        text: "保障因业务涉及场所，如用户家中、服务店面等，所产生的财产损失风险及相应的责任风险",
      },
      {
        icon: shenghuo_jiazhi_3,
        title: "维护平台资产与商誉",
        text: "助力平台合规稳健经营，保障平台在面对高频、海量、创新业务时，存在的各类资产及责任风险",
      },
    ],
  },
  医疗健康: {
    card: [
      {
        icon: yiliao_jiazhi_1,
        title: "对用户暴露的健康风险缺乏提供精准保障的手段",
        text: "企业直面洞悉用户健康风险，但缺乏灵活、精准的保障解决方案，难以提升用户服务体验",
      },
      {
        icon: yiliao_jiazhi_2,
        title: "保障专业人员因疏忽或过失对用户造成的损失",
        text: "对从业人员专业要求高，其工作场景复杂，工作流程精细化程度高，存在各类职业责任风险。",
      },
      {
        icon: yiliao_jiazhi_3,
        title: "协同产业链条、创新业务过程中滋生的风险",
        text: "企业在拓展业务或创新产品过程中，存在产品责任风险及职业责任风险、人身风险",
      },
    ],
  },
  宠物消费: {
    card: [
      {
        icon: chongwu_jiazhi_1,
        title: "提供宠物医疗保障",
        text: "保障爱宠罹患传染病、皮肤病等各类疾病治疗费用，减轻宠物主生活负担",
      },
      {
        icon: chongwu_jiazhi_2,
        title: "保障第三者风险",
        text: "保障宠物主照看不当，袭击、撕咬造成第三者造成的损失",
      },
      {
        icon: chongwu_jiazhi_3,
        title: "场所财产和责任风险",
        text: "保障宠物医院、商店、展会活动等经营风险和财产损失",
      },
    ],
  },
  智能硬件: {
    card: [
      {
        icon: zhineng_jiazhi_1,
        title: "保障智能硬件质量与意外风险",
        text: "保障消费者因智能硬件的产品质量、使用意外等风险，所造成的修理、退货、更换的损失",
      },
      {
        icon: zhineng_jiazhi_2,
        title: "保障物联网平台系统安全风险",
        text: "保障AI、IOT等创新技术使用下，所面临的系统攻击、数据泄露、网络安全风险",
      },
      {
        icon: zhineng_jiazhi_3,
        title: "保障用户使用场景中面临的风险",
        text: "保障因使用智能硬件造成的用户人身或财产损失，或者第三方损失",
      },
    ],
  },
  运动健身: {
    card: [
      {
        icon: yundong_jiazhi_1,
        title: "全面保障数字消费链路中所涉及的各种风险",
        text: "保障机构及平台提供运动、健身服务场景，下面临的各类意外、财产安全、以及责任风险，避免对持续经营及品牌形象造成影响",
      },
      {
        icon: yundong_jiazhi_2,
        title: "保障企业用户在运动场景内常见的高频风险",
        text: "保障用户在健身、运动、参与赛事、享受服务过程中可能发生的意外或权益受损，提升用户在平台上的持续体验",
      },
      {
        icon: yundong_jiazhi_3,
        title: "保障方案灵活、智能，能够充分融入企业业务及运营服务",
        text: "将保险保障以用户友好的方式与业务场景深度融合",
      },
    ],
  },
  灵活用工: {
    card: [
      {
        icon: linghuo_jiazhi_1,
        title: "保障劳动者健康与意外风险",
        text: "灵活用工劳动者工作不稳定、缺乏社会保障，保障劳动者面临的健康风险与意外风险损失",
      },
      {
        icon: linghuo_jiazhi_2,
        title: "保障雇主用工风险",
        text: "保障因灵活用工形态下、劳动时间、工作地点、工作内容多元所带来的各式风险",
      },
      {
        icon: linghuo_jiazhi_3,
        title: "维护平台交付与商誉风险",
        text: "助力行业规范稳定发展，保障平台在交付与管理劳动者质量方面的风险",
      },
    ],
  },
  出行旅游: {
    card: [
      {
        icon: chuxing_jiazhi_1,
        title: "保障消费者在出行旅游中所面临的各类高频风险",
        text: "针对“游”“行”“住”过程中各环节的人身意外、财产损失等高频风险均能提供保障",
      },
      {
        icon: chuxing_jiazhi_2,
        title: "充分融入业务及运营服务，助力企业提升用户体验",
        text: "向消费者提供从投保到理赔全数字化体验，提升对企业的好感度与信任感",
      },
      {
        icon: chuxing_jiazhi_3,
        title: "保障企业及平台自身财产风险及责任风险",
        text: "以综合视角评估企业及平台自身风险情况，帮助企业降低资金损失",
      },
    ],
  },
  企业服务: {
    card: [
      {
        icon: qiye_jiazhi_1,
        title: "保障企业经营风险",
        text: "保障企业面临的网络安全、财产损失、营业中断、公众责任等风险",
      },
      {
        icon: qiye_jiazhi_2,
        title: "保障企业用工风险",
        text: "保障员工工作期间的意外风险，职业病风险，分散责任风险",
      },
      {
        icon: qiye_jiazhi_3,
        title: "帮助企业完善福利体系",
        text: "契合企业的人才管理目标和员工体验诉求，提升员工敬业度和满意度",
      },
    ],
  },
  餐饮消费: {
    card: [
      {
        icon: canyin_jiazhi_1,
        title: "保障人员安全与健康",
        text: "保障餐饮从业人员，从业人员工作过程中所产生的各类意外风险，以及自身的健康疾病风险。",
      },
      {
        icon: canyin_jiazhi_2,
        title: "保障场所财产与人员责任",
        text: "保障餐饮涉及场所财产保障，以及在经营过程中，因认为疏漏发生意外事故或所产生的货物损失风险等。",
      },
      {
        icon: canyin_jiazhi_3,
        title: "维护企业资产与商誉",
        text: "提供企业合规经营所需如食品安全责任保险；提供全面风险管理帮助企业识别供应链重要风险并输出相应保障方案。",
      },
    ],
  },
  金融保障: {
    card: [
      {
        icon: jinrong_jiazhi_1,
        title: "为合作方提供贴合用户核心需求的定制化保险产品",
        text: "主动深入到合作方的主营业务场景中，洞察不同用户群体的保险需求，定制保险产品，创造全新合作模式",
      },
      {
        icon: jinrong_jiazhi_2,
        title: "提供风控、合规、保障一体化管理",
        text: "提供科技解决方案，助力银行升级保险业务，实现新增长，助力保险代理机构，保险经纪机构实现数字化升级",
      },
      {
        icon: jinrong_jiazhi_3,
        title: "一键开启专属保险频道，极速启动业务",
        text: "提供科技解决方案，助力银行升级保险业务，实现新增长，助力保险代理机构，保险代理机构实现数字化升级",
      },
    ],
  },
  智慧社区: {
    card: [
      {
        icon: zhihui_jiazhi_1,
        title: "保障业主家庭财产风险",
        text: "提供业主家庭财产综合保险保障，通过保险转嫁风险损失，减少邻里、物业纠纷",
      },
      {
        icon: zhihui_jiazhi_2,
        title: "帮助物业、社区安全管理",
        text: "提供安防设备、智能家居等产品责任保障，系统网络安全保障，帮助物业打造安全社区",
      },
      {
        icon: zhihui_jiazhi_3,
        title: "解决用户维修服务需求",
        text: "通过引入优质上门服务，解决业主维修、开锁等需求痛点，提升物业服务品质",
      },
    ],
  },
  防伪溯源: {
    card: [
      {
        icon: fangwei_jiazhi_1,
        title: "增强企业及其产品信誉",
        text: "为产品溯源流通数据的真实性，提供第三方保险保障，助力企业打造一物一码信息化防伪追溯体系闭环，增强企业及其产品信誉",
      },
      {
        icon: fangwei_jiazhi_2,
        title: "提升商超、电商、海淘等平台客户服务体验",
        text: "协助平台保护消费者合法权益，简化维权流程，以保险提供的赔偿金、服务补偿等方式处理交易纠纷，有效提升售后体验，助力平台合规稳健经营",
      },
      {
        icon: fangwei_jiazhi_3,
        title: "全面保障数字消费链路中所涉及的各种风险",
        text: "秦保依托在电商生态丰富的业务经验，提供除产品溯源保险外数十款标准交易保障方案，助力合作方进一步打造产品生产、仓储物流、终端销售、市场消费全流程风险保障体系",
      },
    ],
  },
};
const router = useRouter();
let type = ref("交易保障");
//根据query值的变化，展示不同解决方案的数据
onMounted(() => {
  if (router.currentRoute.value.query.id) {
    type.value = props.contrary[router.currentRoute.value.query.id];
  }
});
//根据query值的变化，展示不同解决方案的数据
watch(
  () => router.currentRoute.value.query.id,
  (newValue) => {
    if (newValue) {
      type.value = props.contrary[router.currentRoute.value.query.id];
    }
  }
);
</script>

<style scoped>
.nav {
  position: relative;
  height: 757px;
}
.conxt {
  position: absolute;
  width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 110px;
}
.card {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
.find {
  /* width: 380px; */
  /* height: 372px; */
  background: #f7f7f9;
  border-radius: 10px;
}
.find .p1 {
  width: 340px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2a2a2a;
  margin: 25px 0 14px 20px;
}
.p2 span:nth-child(1) {
  display: inline-block;
  width: 9px;
  height: 9px;
  background: #f8f1ed;
  border: 1px solid rgba(176, 158, 144, 1);
  border-radius: 6px;
  margin-left: 20px;
}
.p2 span:nth-child(2) {
  display: inline-block;
  width: 300px;
  height: 1px;
  background: linear-gradient(270deg, rgba(217, 206, 193, 0) 0%, #daccc4 100%);
  vertical-align: middle;
}
.find .p3 {
  width: 340px;
  font-size: 14px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #848484;
  margin: 14px 0 20px 20px;
  line-height: 20px;
}
.find img {
  width: 380px;
  height: 225px;
  transition: all 1s;
}
.find img:hover {
  transform: scale(1.1);
}
</style>
