<template>
  <div :class="ClassName">
    <div class="conxt">
      <TitleComponent :text="text"></TitleComponent>
      <ul>
        <li v-for="(item, index) in data[type].card" :key="index">
          <!-- <img :src="item.icon" alt=""> -->
          <img v-lazy="item.icon" alt="" :key="item.icon" />
          <div>
            {{ item.text }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import TitleComponent from "../../../../components/titleComponent.vue";
//交易保障
import b2b from "../../../../assets/img/transaction/jiaoyibaozhang/b2b.png";
import b2c from "../../../../assets/img/transaction/jiaoyibaozhang/b2c.png";
import o2o from "../../../../assets/img/transaction/jiaoyibaozhang/o2o.png";
import kuajingdianshang from "../../../../assets/img/transaction/jiaoyibaozhang/kuajingdianshang.png";
import saas from "../../../../assets/img/transaction/jiaoyibaozhang/saas.png";
import erp from "../../../../assets/img/transaction/jiaoyibaozhang/erp.png";
import wiliu from "../../../../assets/img/transaction/jiaoyibaozhang/wiliu.png";
import zhifu from "../../../../assets/img/transaction/jiaoyibaozhang/zhifu.png";
import mendian from "../../../../assets/img/transaction/jiaoyibaozhang/mendian.png";
//生活服务
import canyin from "../../../../assets/img/transaction/shenghuofuwu/canyin.png";
import jiazheng from "../../../../assets/img/transaction/shenghuofuwu/jiazheng.png";
import jiazhuang from "../../../../assets/img/transaction/shenghuofuwu/jiazhuang.png";
import banjia from "../../../../assets/img/transaction/shenghuofuwu/banjia.png";
import meiye from "../../../../assets/img/transaction/shenghuofuwu/meiye.png";
import xihu from "../../../../assets/img/transaction/shenghuofuwu/xihu.png";
import minsu from "../../../../assets/img/transaction/shenghuofuwu/minsu.png";
import chongdianbao from "../../../../assets/img/transaction/shenghuofuwu/chongdianbao.png";
import danche from "../../../../assets/img/transaction/shenghuofuwu/danche.png";
import wangyueche from "../../../../assets/img/transaction/shenghuofuwu/wangyueche.png";
//医疗健康
import tijian from "../../../../assets/img/transaction/yiliaojiankang/tijian.png";
import yiyuan from "../../../../assets/img/transaction/yiliaojiankang/yiyuan.png";
import yiyao from "../../../../assets/img/transaction/yiliaojiankang/yiyao.png";
import zhenhou from "../../../../assets/img/transaction/yiliaojiankang/zhenhou.png";
import wenzhen from "../../../../assets/img/transaction/yiliaojiankang/wenzhen.png";
import yingjian from "../../../../assets/img/transaction/yiliaojiankang/yingjian.png";
import yiliao from "../../../../assets/img/transaction/yiliaojiankang/yiliao.png";
import jijin from "../../../../assets/img/transaction/yiliaojiankang/jijin.png";
import yiyao1 from "../../../../assets/img/transaction/yiliaojiankang/yiyao(1).png";
import jiankang from "../../../../assets/img/transaction/yiliaojiankang/jiankang.png";
//宠物消费
import chongwuyiyuan from "../../../../assets/img/transaction/chongwuxiaofei/yiyuan.png";
import chongwuxihu from "../../../../assets/img/transaction/chongwuxiaofei/xihu.png";
import shiping from "../../../../assets/img/transaction/chongwuxiaofei/shipin.png";
import yaoping from "../../../../assets/img/transaction/chongwuxiaofei/yaoping.png";
import lingyang from "../../../../assets/img/transaction/chongwuxiaofei/lingyang.png";
import huodong from "../../../../assets/img/transaction/chongwuxiaofei/huodong.png";
import chongwuyunshu from "../../../../assets/img/transaction/chongwuxiaofei/yunshu.png";
//智能硬件
import shouji from "../../../../assets/img/transaction/zhinengyinjian/shouji.png";
import wurenji from "../../../../assets/img/transaction/zhinengyinjian/wurenji.png";
import zhinengjiaju from "../../../../assets/img/transaction/zhinengyinjian/zhinengjiaju.png";
import chuandai from "../../../../assets/img/transaction/zhinengyinjian/chuandai.png";
import yuanqijian from "../../../../assets/img/transaction/zhinengyinjian/yuanqijian.png";
import yingyin from "../../../../assets/img/transaction/zhinengyinjian/yingyin.png";
import anfang from "../../../../assets/img/transaction/zhinengyinjian/anfang.png";
import iot from "../../../../assets/img/transaction/zhinengyinjian/iot.png";
import yunshu from "../../../../assets/img/transaction/zhinengyinjian/yunshu.png";
//运动健身
import yundong from "../../../../assets/img/transaction/yundongjiansheng/yundong.png";
import app from "../../../../assets/img/transaction/yundongjiansheng/app.png";
import saishi from "../../../../assets/img/transaction/yundongjiansheng/saishi.png";
import dianshang from "../../../../assets/img/transaction/yundongjiansheng/dianshang.png";
import yongpin from "../../../../assets/img/transaction/yundongjiansheng/yongpin.png";
import peixun from "../../../../assets/img/transaction/yundongjiansheng/peixun.png";
import yundongyingjian from "../../../../assets/img/transaction/yundongjiansheng/yingjian.png";
import huwai from "../../../../assets/img/transaction/yundongjiansheng/huwai.png";
import yundongjiankang from "../../../../assets/img/transaction/yundongjiansheng/jiankang.png";
//灵活用工
import renli from "../../../../assets/img/transaction/linghuoyonggong/renli.png";
import zhongbao from "../../../../assets/img/transaction/linghuoyonggong/zhongbao.png";
import shixi from "../../../../assets/img/transaction/linghuoyonggong/shixi.png";
import xinshui from "../../../../assets/img/transaction/linghuoyonggong/xinshui.png";
import renliziyuan from "../../../../assets/img/transaction/linghuoyonggong/renliziyuan.png";
import fuli from "../../../../assets/img/transaction/linghuoyonggong/fuli.png";
//出行旅游
import lvyou from "../../../../assets/img/transaction/chuxinglvyou/lvyou.png";
import hangkong from "../../../../assets/img/transaction/chuxinglvyou/hangkong.png";
import zuche from "../../../../assets/img/transaction/chuxinglvyou/zuche.png";
import jiudian from "../../../../assets/img/transaction/chuxinglvyou/jiudian.png";
import xinxi from "../../../../assets/img/transaction/chuxinglvyou/xinxi.png";
import lvxingshe from "../../../../assets/img/transaction/chuxinglvyou/lvxingshe.png";
import piaowu from "../../../../assets/img/transaction/chuxinglvyou/piaowu.png";
import jingqu from "../../../../assets/img/transaction/chuxinglvyou/jingqu.png";
//企业服务
import chanyeyuan from "../../../../assets/img/transaction/qiyefuwu/chanyeyuan.png";
import zhongxiaowei from "../../../../assets/img/transaction/qiyefuwu/zhongxiaowei.png";
import qiyerenliziyuan from "../../../../assets/img/transaction/qiyefuwu/renliziyuan.png";
import qisaas from "../../../../assets/img/transaction/qiyefuwu/saas.png";
import jituan from "../../../../assets/img/transaction/qiyefuwu/jituan.png";
import chuangye from "../../../../assets/img/transaction/qiyefuwu/chuangye.png";
import jigou from "../../../../assets/img/transaction/qiyefuwu/jigou.png";
//餐饮消费
import canting from "../../../../assets/img/transaction/canyinxiaofei/canting.png";
import tuancan from "../../../../assets/img/transaction/canyinxiaofei/tuancan.png";
import yinpin from "../../../../assets/img/transaction/canyinxiaofei/yinpin.png";
import xitong from "../../../../assets/img/transaction/canyinxiaofei/xitong.png";
import canyingo2o from "../../../../assets/img/transaction/canyinxiaofei/o2o.png";
import caigou from "../../../../assets/img/transaction/canyinxiaofei/caigou.png";
import jiagong from "../../../../assets/img/transaction/canyinxiaofei/jiagong.png";
//金融保障
import yinhang from "../../../../assets/img/transaction/jinrongbaozhang/yinhang.png";
import daili from "../../../../assets/img/transaction/jinrongbaozhang/daili.png";
import jingji from "../../../../assets/img/transaction/jinrongbaozhang/jingji.png";
//智慧社区
import wuye from "../../../../assets/img/transaction/zhihuishequ/wuye.png";
import shebei from "../../../../assets/img/transaction/zhihuishequ/shebei.png";
import yanglao from "../../../../assets/img/transaction/zhihuishequ/yanglao.png";
import zhihuianfang from "../../../../assets/img/transaction/zhihuishequ/anfang.png";
import shangwu from "../../../../assets/img/transaction/zhihuishequ/shangwu.png";
import shenghuo from "../../../../assets/img/transaction/zhihuishequ/shenghuo.png";
import jiaju from "../../../../assets/img/transaction/zhihuishequ/jiaju.png";
import jianzhu from "../../../../assets/img/transaction/zhihuishequ/jianzhu.png";
import zhihuiyiliao from "../../../../assets/img/transaction/zhihuishequ/yiliao.png";
//防伪溯源
import cany from "../../../../assets/img/transaction/fangweisuyuan/cany.png";
import scp from "../../../../assets/img/transaction/fangweisuyuan/scp.png";
import rihua from "../../../../assets/img/transaction/fangweisuyuan/rihua.png";
import ship from "../../../../assets/img/transaction/fangweisuyuan/ship.png";
import yanjiu from "../../../../assets/img/transaction/fangweisuyuan/yanjiu.png";
import fangweijiaju from "../../../../assets/img/transaction/fangweisuyuan/jiaju.png";
import yishuping from "../../../../assets/img/transaction/fangweisuyuan/yisuping.png";
import zuanshi from "../../../../assets/img/transaction/fangweisuyuan/zuanshi.png";
import qiche from "../../../../assets/img/transaction/fangweisuyuan/qiche.png";
import fangweiyiliao from "../../../../assets/img/transaction/fangweisuyuan/yiliao.png";

const text = "我们能为这些场景提供解决方案";
const props = defineProps(["contrary"]);
const data = {
  交易保障: {
    card: [
      {
        icon: b2b,
        text: "B2B商城",
      },
      {
        icon: b2c,
        text: "B2C商城",
      },
      {
        icon: o2o,
        text: "O2O商城",
      },
      {
        icon: kuajingdianshang,
        text: "跨境电商平台",
      },
      {
        icon: saas,
        text: "SaaS系统服务",
      },
      {
        icon: erp,
        text: "ERP软件服务",
      },
      {
        icon: wiliu,
        text: "物流服务",
      },
      {
        icon: zhifu,
        text: "第三方支付",
      },
      {
        icon: mendian,
        text: "门店管理系统",
      },
    ],
  },
  生活服务: {
    card: [
      {
        icon: canyin,
        text: "餐饮平台",
      },
      {
        icon: jiazheng,
        text: "家政平台",
      },
      {
        icon: jiazhuang,
        text: "家装平台",
      },
      {
        icon: banjia,
        text: "货运搬家平台",
      },
      {
        icon: meiye,
        text: "美业平台",
      },
      {
        icon: xihu,
        text: "洗护平台",
      },
      {
        icon: minsu,
        text: "短租/民宿",
      },
      {
        icon: chongdianbao,
        text: "共享充电宝",
      },
      {
        icon: danche,
        text: "共享单车",
      },
      {
        icon: wangyueche,
        text: "网约车/顺风车平台",
      },
    ],
  },
  医疗健康: {
    card: [
      {
        icon: tijian,
        text: "体检机构",
      },
      {
        icon: yiyuan,
        text: "医院",
      },
      {
        icon: yiyao,
        text: "医药连锁",
      },
      {
        icon: zhenhou,
        text: "诊后康复",
      },
      {
        icon: wenzhen,
        text: "在线问诊",
      },
      {
        icon: yingjian,
        text: "健康智能硬件",
      },
      {
        icon: yiliao,
        text: "医疗器械",
      },
      {
        icon: jijin,
        text: "基因测序",
      },
      {
        icon: yiyao1,
        text: "医药研发",
      },
      {
        icon: jiankang,
        text: "健康管理",
      },
    ],
  },
  宠物消费: {
    card: [
      {
        icon: chongwuyiyuan,
        text: "宠物医院",
      },
      {
        icon: chongwuxihu,
        text: "洗护/美容",
      },
      {
        icon: shiping,
        text: "食品/用品",
      },
      {
        icon: yaoping,
        text: "宠物药品",
      },
      {
        icon: lingyang,
        text: "领养/寄养",
      },
      {
        icon: huodong,
        text: "活动/展会",
      },
      {
        icon: chongwuyunshu,
        text: "运输/养殖",
      },
    ],
  },
  智能硬件: {
    card: [
      {
        icon: shouji,
        text: "智能手机",
      },
      {
        icon: wurenji,
        text: "无人机",
      },
      {
        icon: zhinengjiaju,
        text: "智能家电",
      },
      {
        icon: chuandai,
        text: "智能穿戴",
      },
      {
        icon: yuanqijian,
        text: "元器件厂商",
      },
      {
        icon: yingyin,
        text: "智能影音",
      },
      {
        icon: anfang,
        text: "智能安防",
      },
      {
        icon: iot,
        text: "IOT开放平台",
      },
      {
        icon: yunshu,
        text: "云/数据服务",
      },
    ],
  },
  运动健身: {
    card: [
      {
        icon: yundong,
        text: "运动场馆",
      },
      {
        icon: app,
        text: "运动类APP",
      },
      {
        icon: saishi,
        text: "运动赛事组织",
      },
      {
        icon: dianshang,
        text: "运动电商",
      },
      {
        icon: yongpin,
        text: "体育用品品牌",
      },
      {
        icon: peixun,
        text: "体育培训机构",
      },
      {
        icon: yundongyingjian,
        text: "运动智能硬件",
      },
      {
        icon: huwai,
        text: "户外素质拓展",
      },
      {
        icon: yundongjiankang,
        text: "健康管理服务",
      },
    ],
  },
  灵活用工: {
    card: [
      {
        icon: renli,
        text: "人力资源外包",
      },
      {
        icon: zhongbao,
        text: "众包平台",
      },
      {
        icon: shixi,
        text: "兼职/实习平台",
      },
      {
        icon: xinshui,
        text: "薪税服务平台",
      },
      {
        icon: renliziyuan,
        text: "人事管理平台",
      },
      {
        icon: fuli,
        text: "福利保障平台",
      },
    ],
  },
  出行旅游: {
    card: [
      {
        icon: lvyou,
        text: "在线旅游平台",
      },
      {
        icon: hangkong,
        text: "航空公司",
      },
      {
        icon: zuche,
        text: "租车公司",
      },
      {
        icon: jiudian,
        text: "酒店民宿",
      },
      {
        icon: xinxi,
        text: "出行信息平台",
      },
      {
        icon: lvxingshe,
        text: "旅行社",
      },
      {
        icon: piaowu,
        text: "智能票务公司",
      },
      {
        icon: jingqu,
        text: "景区管理公司",
      },
    ],
  },
  企业服务: {
    card: [
      {
        icon: chanyeyuan,
        text: "产业园区服务",
      },
      {
        icon: zhongxiaowei,
        text: "中小微企业",
      },
      {
        icon: qiyerenliziyuan,
        text: "人力资源服务",
      },
      {
        icon: qisaas,
        text: "企业SaaS",
      },
      {
        icon: jituan,
        text: "集团公司",
      },
      {
        icon: chuangye,
        text: "创业孵化",
      },
      {
        icon: jigou,
        text: "专业服务机构",
      },
    ],
  },
  餐饮消费: {
    card: [
      {
        icon: canting,
        text: "连锁餐厅企业",
      },
      {
        icon: tuancan,
        text: "团餐连锁企业",
      },
      {
        icon: yinpin,
        text: "饮品甜品连锁",
      },
      {
        icon: xitong,
        text: "智慧餐饮系统",
      },
      {
        icon: canyingo2o,
        text: "餐饮O2O企业",
      },
      {
        icon: caigou,
        text: "食材采购平台",
      },
      {
        icon: jiagong,
        text: "净菜食材加工企业",
      },
    ],
  },
  金融保障: {
    card: [
      {
        icon: yinhang,
        text: "银行",
      },
      {
        icon: daili,
        text: "保险代理公司",
      },
      {
        icon: jingji,
        text: "保险经纪公司",
      },
    ],
  },
  智慧社区: {
    card: [
      {
        icon: wuye,
        text: "物业管理",
      },
      {
        icon: shebei,
        text: "智能设备",
      },
      {
        icon: yanglao,
        text: "养老服务",
      },
      {
        icon: zhihuianfang,
        text: "家居安防",
      },
      {
        icon: shangwu,
        text: "电子商务服务",
      },
      {
        icon: shenghuo,
        text: "生活服务",
      },
      {
        icon: jiaju,
        text: "智能家居",
      },
      {
        icon: jianzhu,
        text: "智能建筑",
      },
      {
        icon: zhihuiyiliao,
        text: "移动医疗",
      },
    ],
  },
  防伪溯源: {
    card: [
      {
        icon: cany,
        text: "餐饮平台",
      },
      {
        icon: scp,
        text: "奢侈品",
      },
      {
        icon: rihua,
        text: "日化用品",
      },
      {
        icon: ship,
        text: "食品",
      },
      {
        icon: yanjiu,
        text: "烟酒茶",
      },
      {
        icon: fangweijiaju,
        text: "家居",
      },
      {
        icon: yishuping,
        text: "艺术品",
      },
      {
        icon: zuanshi,
        text: "钻石",
      },
      {
        icon: qiche,
        text: "汽车零件",
      },
      {
        icon: fangweiyiliao,
        text: "医疗器械",
      },
    ],
  },
};
const router = useRouter();
let type = ref("交易保障");
let ClassName = ref("nav");
//根据query值的变化，展示不同解决方案的数据
onMounted(() => {
  if (router.currentRoute.value.query.id) {
    type.value = props.contrary[router.currentRoute.value.query.id];
    //当解决方案小于五个时，改变整体高度
    if (data[type.value].card.length < 5) {
      ClassName.value = "nav1";
    }
  }
});
//根据query值的变化，展示不同解决方案的数据
watch(
  () => router.currentRoute.value.query.id,
  (newValue) => {
    if (newValue) {
      type.value = props.contrary[router.currentRoute.value.query.id];
      //当解决方案小于五个时，改变整体高度
      if (data[type.value].card.length < 5) {
        ClassName.value = "nav1";
      } else {
        ClassName.value = "nav";
      }
    }
  }
);
</script>

<style scoped>
.nav {
  position: relative;
  height: 819px;
  background: #f7f7f9;
}
.nav1 {
  position: relative;
  height: 604px;
  background: #f7f7f9;
}
.conxt {
  position: absolute;
  width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 110px;
}
.conxt ul {
  display: flex;
  width: 1250px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.conxt ul li {
  list-style: none;
  width: 220px;
  height: 220px;
  background: #ffffff;
  border-radius: 20px;
  margin: 30px 20px 0 0;
}
.conxt ul li img {
  width: 143px;
  height: 112px;
  margin: 34px 0 0 39px;
}
.conxt ul li div {
  width: 180px;
  height: 35px;
  background: #f7f7f9;
  border-radius: 5px;
  margin: 4px 0 0 20px;
  text-align: center;
  line-height: 35px;
  color: #35445e;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
</style>
